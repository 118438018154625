<template>
  <div>
    <section>
      <header>
        <h3>
          <b-row align-v="center">
            <b-col :style="onMobile ? 'text-align:center' : 'text-align:left;'" md="1">
              <BackButton :noBack="this.$parent.onHomePage < 2"/> <!--  -->
            </b-col>
            <b-col md="10" style="text-align:center;">
              {{$t('tiTroviVicinoA')}} {{s(this.$store.getters.getPivotSelezionato,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])}}
            </b-col>
            <!--
            <b-col md="1" :style="onMobile ? 'text-align:center' : 'text-align:right;'">
              <FavouritesButton />
            </b-col>
            -->
          </b-row>
        </h3>
      </header>
      <blockquote v-if="this.$store.getters.getPivotSelezionato !== null" class="blck">
          <p aria-hidden="true"><span v-html="descr[0]"></span> <a href="#" v-if="descr[1]" @click.prevent="expand()">{{$t("scopri")}}</a></p>
          <a class="sr-only">{{complete_descr}}</a>
      </blockquote>
      <!-- <Media 
        v-if="this.$store.getters.getPivotImages !== null"
        :media="this.$store.getters.getPivotImages"
        :handleText="false"
      /> -->
    </section>
    <section>
      <collapse>
        <collapse-item :title="$t('connessoAiTemi')" :initiallyActive="true">
          <CardList
            list-title="Connesso ai temi"
            :elems="this.maglie" 
            :handler="this.seeDetails"
            :thumbnail="this.thumbnailSrc"
            :alt="this.alt"
            :title="this.cardTitle"
            :body="this.cardBody"
            v-if="this.$store.getters.getPivotSelezionato !== null && this.loaded" 
            />
        </collapse-item>
      </collapse>
    </section>
    </div>
</template>
<script>
import {Button} from '@/components'
import CardList from './components/CardList.vue'
//import Media from './components/Media.vue'
import Sources from './components/Sources.vue'
//import FavouritesButton from './components/FavouritesButton.vue'
import {Collapse, CollapseItem} from '@/components'
import BackButton from './components/BackButton.vue'
import {
  hyphenateSync
} from "hyphen/it";

const Common = require('@/Common.vue').default
export default {
  components: {[Button.name]: Button, CardList, BackButton, Collapse, CollapseItem },
  name: 'magliefrompivot',
  props: {
            pivotId : {
                type: Number,
                required: true
            },
            ongoing: {
                type:Boolean,
                required: false
            }
        },
  data: function(){
    return {
      maglie : [], // Tutte le maglie apparententi al pivot selezionato. Sono oggetti del tipo 'maglia':maglia,percorsoId:id del percorso associato a pivot corrente + maglia
      currentPercorso : -1, // Il percorso selezionato
      loaded: false,
      images: {}, // Resource (maglia) id => media object (thumbnail)
      expandedDescription: false
    }
  },
  mounted : function(){
      // Tri-LOG
      Common.addLog(this,this.$route.path, res => {});
      var self = this;
      Common.getElemByPropertyId(this,259,this.$props.pivotId, function(response){

        var waiting = response.body.length
        response.body.forEach(el => {
          Common.getElemByUrl(self,self.s(el,["tri:appartiene_a_maglia",0,"@id"]),res => {
            

            if(self.s(res.body,['dcterms:accessRights',0,'@value']) === "YES" || localStorage.getItem("admin") === self.$store.getters.getPw){
              self.maglie.push({'maglia':res.body,'percorsoId':el['o:id']})
              Common.getElementImages(self,res.body, mediaList => {
                self.images[res.body["o:id"]] = mediaList
                waiting--
                if(waiting === 0)
                  self.loaded = true
              })
            }
            else{
              waiting--
              if(waiting === 0)
                self.loaded = true
            }
          })
        })
      })
  },
  methods: {
    s: function(e,a,d=null){
      return Common.safeString(e,a,d)
    },
    expand: function(){
      Common.addUserStudyLog(this, 'Expand-MaglieFromPivot', {
        'pivotId' : this.$props.pivotId
      })
      this.expandedDescription = true
    },
    seeDetails : function(m){
      this.$router.push({path: '/maglia/'+m['percorsoId']})
    },
    mainSrc : function(){return this.s(this.images,[this.$store.getters.getPivotSelezionato["o:id"],'thumbnail_display_urls','large'])},
    thumbnailSrc: function(elem){return this.s(Common.findThumbnail(this.s(this.images,[elem['maglia']['o:id']])),['thumbnail_display_urls','square'])},
    alt: function(elem){return 'Immagine per ' + this.s(elem['maglia'],["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
    cardTitle: function(elem){return this.s(elem['maglia'],["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])},
    cardBody: function(elem){
      return this.s(elem['maglia'],["tri:Descrizione_" + this.$store.getters.getLanguage,0,"@value"])
    },
    /*back : function(){
      // Torna alla pagina principale
      this.$router.push({path: '/'})
    },*/
    elValue: function(el){
        return Common.safeString(el,["o:title"]) + " " + Common.safeString(el,["o:source"])
    },

  },
  computed: {
    complete_descr: function(){
      return this.s(this.$store.getters.getPivotSelezionato,["dcterms:description",0,"@value"])
    },
    descr: function(){
      var complete = Common.processMarkdown(this.complete_descr)
      if(complete.length <= 350 || this.expandedDescription)
        return [hyphenateSync(complete),false]
      else
        return [hyphenateSync(complete.substr(0,347)) + '...',true]
    },
    imgUrl : function(){
      if(!this.$store.getters.getPivotSelezionato || this.$route.name === 'index' || this.$store.getters.getPivotImages === null)
        return "../img/bg5.jpg";
      else{
        return Common.safeString(Common.findMainImage(this.$store.getters.getPivotImages),['thumbnail_display_urls','square']);
      }
    },
    favTitle: function(){
      return Common.safeString(this.$store.getters.getPivotSelezionato,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"])
    },
    text: function(){
      if(this.$store.getters.getPivotImages === null)
        return []
      return this.$store.getters.getPivotImages.filter(el => el["o:media_type"] === 'text/html');
    },
    onMobile: function(){
      return window.innerWidth < 768
    },
  },
  watch : {
    complete_descr(n,o){
      if(n)
        Common.addLlmContextMessage(this, 'maglie-pivot', this.s(this.$store.getters.getPivotSelezionato,["tri:Titolo_"+this.$store.getters.getLanguage,0,"@value"]), this.complete_descr)
    }
  }
};
</script>
<style scoped>
h3{
  text-align:center;
  margin-top:3%;
}
CardList{
  margin-top:3%
}
.blck{
  padding-top: 0;
  padding-bottom: 0;
}
p, span{
  text-align: justify;
  text-justify: inter-word;
}
</style>